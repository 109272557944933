.reform-slider-initialized {
	position: relative;
	overflow: hidden;
}

.reform-slide {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: 1;
}

.reform-slide-current {
	z-index: 2;
}

